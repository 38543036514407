import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {environment} from '@env/environment';
import {Exception404Component} from '@showye/showye-page-admin/layout-admin';
import {ShowyePageAuthGuard} from '@showye/showye-page-base';
import {LayoutComponent} from '../layout/layout.component';

const routes: Routes = [
  {path: 'passport', loadChildren: () => import('./login/passport.module').then((m) => m.PassportModule)},
  {
    path: '',
    component: LayoutComponent,
    canActivate: [ShowyePageAuthGuard],
    canActivateChild: [ShowyePageAuthGuard],
    children: [
      {path: 'exception', children: [{path: '404', component: Exception404Component}]},
      // 在下面放置 业务模块 的内容
      {path: '', children: [{path: '', redirectTo: '/device/type', pathMatch: 'full'}]},
      // 设备管理
      {
        path: 'device',
        children: [
          {path: '', redirectTo: '/device/type', pathMatch: 'full'},
          {path: 'type', loadChildren: () => import('./device-type/device-type.module').then((m) => m.DeviceTypeModule)},
          {path: 'manage', loadChildren: () => import('./device/device.module').then((m) => m.DeviceModule)},
        ],
      },
      // 场景管理
      {
        path: 'scene',
        children: [
          {path: '', redirectTo: '/scene/config', pathMatch: 'full'},
          {path: 'config', loadChildren: () => import('./scene/scene.module').then((m) => m.SceneModule)},
          {path: 'test', loadChildren: () => import('./test/test.module').then((m) => m.TestModule)},
          {path: 'event', loadChildren: () => import('./event/event.module').then((m) => m.EventModule)},
        ],
      },
      // 子系统管理
      {
        path: 'subsystem',
        children: [
          {path: '', redirectTo: '/subsystem/subsystem', pathMatch: 'full'},
          {path: 'subsystem', loadChildren: () => import('./subsystem/subsystem.module').then((m) => m.SubsystemModule)},
          {path: 'info', loadChildren: () => import('./info/info.module').then((m) => m.InfoModule)},
          {path: 'correspond', loadChildren: () => import('./correspond/correspond.module').then((m) => m.CorrespondModule)},
        ],
      },
      // 系统设置
      {
        path: 'set',
        children: [
          {path: '', redirectTo: '/set/menu', pathMatch: 'full'},
          {path: 'menu', loadChildren: () => import('./set/menu/menu.module').then((m) => m.MenuModule)},
          {path: 'domain', loadChildren: () => import('./set/domain/domain.module').then((m) => m.DomainModule)},
          {path: 'code', loadChildren: () => import('./set/code/code.module').then((m) => m.CodeModule)},
          {path: 'role', loadChildren: () => import('./set/role/role.module').then((m) => m.RoleModule)},
          {path: 'user', loadChildren: () => import('./set/user/user.module').then((m) => m.UserModule)},
          {path: 'log', loadChildren: () => import('./set/log/log.module').then((m) => m.LogModule)},
        ],
      },
    ],
  },
  {path: '**', redirectTo: 'exception/404'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class RouteRoutingModule {
}
