<layout-default [options]="options" [content]="contentTpl" style="position: relative">

  <ng-container *ngIf="menus && menus.length > 0">
    <layout-default-header-item direction="left" *ngFor="let item of menus">
      <a layout-default-header-item-trigger *ngIf="isExternal(item.link); else menuElse" target="_blank">
        <i *ngIf="!!item.icon" nz-icon [nzType]="item.icon"> </i> {{ item.text }}
      </a>
      <ng-template #menuElse>
        <a class="" layout-default-header-item-trigger [routerLink]="item.link">
          <i *ngIf="!!item.icon" nz-icon [nzType]="item.icon"> </i> {{ item.text }}
        </a>
      </ng-template>
    </layout-default-header-item>
  </ng-container>

  <layout-default-header-item direction="right">
    <div style="display: flex">
      <div
        class="alain-default__nav-item d-flex align-items-center px-sm"
        nz-dropdown
        nzPlacement="bottomRight"
        [nzDropdownMenu]="userMenu">
        <nz-avatar [nzSrc]="staffAvatar" nzSize="small" class="mr-sm"></nz-avatar>
        {{ user.name }}
      </div>
    </div>

    <nz-dropdown-menu #userMenu="nzDropdownMenu">
      <div nz-menu class="width-sm">
        <div nz-menu-item (click)="logout()"><i nz-icon nzType="logout" class="mr-sm"></i>退出登录</div>
        <div nz-menu-item (click)="onEditPassword()"><i nz-icon nzType="unlock" class="mr-sm"></i>修改密码</div>
      </div>
    </nz-dropdown-menu>
  </layout-default-header-item>

  <ng-template #contentTpl>
    <div style="position: relative; top: 10px">
      <router-outlet></router-outlet>
    </div>
  </ng-template>
</layout-default>

<nz-modal [(nzVisible)]="showPassModal" [nzTitle]="'修改密码'" (nzOnCancel)="showPassModal = false">
  <ng-container *nzModalContent>
    <form [formGroup]="passwordForm">
      <nz-form-item>
        <nz-form-label [nzSm]="7" [nzXs]="24" nzRequired>请输入旧密码</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <input nz-input type="password" formControlName="oldPass" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="7" [nzXs]="24" nzRequired>请输入新密码</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <input nz-input type="password" formControlName="newPass" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="7" [nzXs]="24" nzRequired>请再次输入新密码</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <input nz-input type="password" formControlName="repeatPass" />
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>
  <div *nzModalFooter>
    <div style="text-align: center">
      <button style="width: 100px; margin-right: 30px" nz-button nzType="default" (click)="showPassModal = false">取消</button>
      <button style="width: 100px" nz-button nzType="primary" (click)="resetPassword()">提交</button>
    </div>
  </div>
</nz-modal>
