import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class API {
  public HOST = environment.host + environment.path;
  // 枚举字典相关
  public ENUM_LIST = `${this.HOST}/core/enum/list`;
  // 文件上传下载
  public UPLOAD = `${this.HOST}/core/file/upload`;
  public DOWNLOAD = `${this.HOST}/core/file/download`;
  /**
   * 用户管理
   */
  // 新增ott用户
  public ADD_OTT_USER = `${this.HOST}/crypto/user/generate-link`;
  //  ott用户注册
  public OTT_USER_GENERATE = `${this.HOST}/crypto/user/generate-user`;
  // 新增用户
  public ADD_USER = `${this.HOST}/admin/user/add`;
  // 编辑用户
  public EDIT_USER = `${this.HOST}/admin/user/edit`;
  // 获取用户详情
  public USER_INFO = (id: any) => `${this.HOST}/admin/user/info/${id}`;
  // 用户列表
  public USER_LIST = `${this.HOST}/admin/user/list`;
  // 修改密码
  public MY_CHANGE_PASSWORD = `${this.HOST}/admin/user/password/reset`;
  public DELETE_USER = (id: any) => `${this.HOST}/admin/user/delete/${id}`;
  // 重置员工登录密码
  public INIT_PWD = (id: any) => `${this.HOST}/admin/user/initPwd/${id}`;
  // 根据部门查询用户列表
  public GET_USER_LIST_BY_DOMAIN = (domainId: any) => `${this.HOST}/admin/user/getUserListByDomainId/${domainId}`;
  /**
   * 组织机构管理
   */
  // 组织机构树
  public DOMAIN_TREE = `${this.HOST}/admin/domain/tree`;
  // 根据组织机构id获取同等级的组织机构列表
  public GET_SAME_LEVEL_DOMAIN_LIST = (id: any) => `${this.HOST}/admin/domain/getSameLevelDomainList/${id}`;
  /**
   * 角色管理
   */
  // 角色列表
  public ROLE_LIST = `${this.HOST}/admin/role/list`;
  // 新增角色
  public ROLE_ADD = `${this.HOST}/admin/role/add`;
  // 编辑角色
  public ROLE_EDIT = `${this.HOST}/admin/role/edit`;
  // 角色详情
  public ROLE_INFO = (id: any) => `${this.HOST}/admin/role/info/${id}`;
  // 删除角色
  public ROLE_DELETE = (id: any) => `${this.HOST}/admin/role/delete/${id}`;
  // 配置角色菜单
  public ROLE_CONFIG = `${this.HOST}/admin/role/roleConfig`;
  // 角色下拉选择
  public ALL_ROLES = `${this.HOST}/admin/role/allRoles`;
  // 获取部门下的角色
  public GET_ROLE_LIST_BY_DOMAIN = (domainId: any) => `${this.HOST}/admin/role/getRoleListByDomainId/${domainId}`;

  /**
   * 菜单管理
   */
  // 菜单树
  public MENU_TREE = `${this.HOST}/admin/menu/tree`;
  // 删除菜单
  public MENU_DELETE = `${this.HOST}/admin/menu/delete`;
  // 菜单按钮权限
  public MY_PERMS = `${this.HOST}/admin/menu/myperms`;

  /**
   *  权限按钮管理
   */
  // 权限树
  public BUTTON_TREE = `${this.HOST}/admin/button/buttonTree`;
  // 新增按钮
  public BUTTON_ADD = `${this.HOST}/admin/button/add`;
  // 编辑按钮
  public BUTTON_EDIT = `${this.HOST}/admin/button/edit`;
  // 删除按钮
  public BUTTON_DELETE = (id: any) => `${this.HOST}/admin/button/delete/${id}`;
  /**
   * 配置管理
   */
  // 数据库数据迁移 --更新数据
  public DATA_BASE_DATA = `${this.HOST}/update/data/data`;

  /***
   * 查询日志
   */
  public LIST_LOG = `${this.HOST}/admin/log/list`;

  /**
   * 设备类型管理
   */
  // 设备类型列表
  public DEVICE_TYPE_LIST = `${this.HOST}/deviceType/list`;
  // 新增二级设备类型
  public ADD_DEVICE_TEMPLATE = `${this.HOST}/deviceTemplate/add`;
  // 修改二级设备类型
  public EDIT_DEVICE_TEMPLATE = `${this.HOST}/deviceTemplate/edit`;
  // 删除二级设备类型
  public DEL_DEVICE_TEMPLATE = (id: any) => `${this.HOST}/deviceTemplate/del/${id}`;
  // 二级设备类型详情
  public DEVICE_TEMPLATE_INFO = (id: any) => `${this.HOST}/deviceTemplate/info/${id}`;
  // 二级设备下拉选择
  public DEVICE_TEMPLATE_LIST_OF_SELECT = `${this.HOST}/deviceTemplate/listOfSelect`;
  // 添加二级设备属性
  public ADD_ATTRIBUTE_DEVICE_TEMPLATE = `${this.HOST}/deviceTemplate/addAttribute`;
  // 编辑二级设备属性
  public EDIT_ATTRIBUTE_DEVICE_TEMPLATE = `${this.HOST}/deviceTemplate/editAttribute`;
  // 编辑二级设备属性是否显示
  public DISPLAY_ATTRIBUTE = (id: any) => `${this.HOST}/deviceTemplate/displayAttribute/${id}`;
  // 删除二级设备属性
  public DELETE_ATTRIBUTE_DEVICE_TEMPLATE = (id: any) => `${this.HOST}/deviceTemplate/deleteAttribute/${id}`;
  // 添加二级设备行为
  public ADD_ACTION_DEVICE_TEMPLATE = `${this.HOST}/deviceTemplate/addAction`;
  // 编辑二级设备行为
  public EDIT_ACTION_DEVICE_TEMPLATE = `${this.HOST}/deviceTemplate/editAction`;
  // 删除二级设备行为
  public DELETE_ACTION_DEVICE_TEMPLATE = (id: any) => `${this.HOST}/deviceTemplate/delAction/${id}`;
  /**
   * 设备管理
   */
  // 设备列表
  public DEVICE_LIST = `${this.HOST}/device/list`;
  // 修改设备公开状态
  public DEVICE_PUBLIC_EDIT = `${this.HOST}/device/publicEdit`;
  // 添加设备
  public ADD_DEVICE = `${this.HOST}/device/add`;
  // 修改设备
  public EDIT_DEVICE = `${this.HOST}/device/edit`;
  // 设备详情
  public DEVICE_INFO = (id: any) => `${this.HOST}/device/info/${id}`;
  // 删除设备
  public DELETE_DEVICE = (id: any) => `${this.HOST}/device/delete/${id}`;
  // 添加属性
  public ADD_ATTRIBUTE_DEVICE = `${this.HOST}/device/addDeviceAttribute`;
  // 修改属性
  public EDIT_ATTRIBUTE_DEVICE = `${this.HOST}/device/editDeviceAttribute`;
  // 设备属性列表
  public ATTRIBUTE_LIST_DEVICE = `${this.HOST}/device/deviceAttribute`;
  // 删除属性
  public DELETE_ATTRIBUTE_DEVICE = (id: any) => `${this.HOST}/device/deleteDeviceAttribute/${id}`;
  // 设备选择器
  public LIST_OF_SELECT_DELIVE = `${this.HOST}/device/listOfSelect`;
  // 设备行为列表
  public ACTION_LIST_DEVICE = (deviceId: any) => `${this.HOST}/device/getActionList/${deviceId}`;
  // 所在场景
  public GET_RELATION_SCENE = (id: any) => `${this.HOST}/device/getRelationScene/${id}`;
  // 通信列表
  public CORRESPOND_LIST_DEVICE = (id: any) => `${this.HOST}/device/correspondList/${id}`;
  // 设备解绑通信
  public UNBIND_CORRESPOND_DEVICE = `${this.HOST}/device/unbindCorrespond`;
  // 设备绑定通信
  public BIND_CORRESPOND_DEVICE = `${this.HOST}/device/bindCorrespond`;
  // 编辑设备通信
  public EDIT_BIND_CORRESPOND_DEVICE = `${this.HOST}/device/editBindCorrespond`;
  /**
   * 场景管理
   */
  // 添加设备并绑定场景
  public ADD_DEVICE_BIND_SCENE = `${this.HOST}/scene/addDeviceBindScene`;
  // 场景绑定设备
  public BIND_DEVICE_SCENE = `${this.HOST}/scene/bindDevice`;
  // 新增场景
  public ADD_SCENE = `${this.HOST}/scene/add`;
  // 删除场景
  public DEL_SCENE = (id: any) => `${this.HOST}/scene/del/${id}`;
  // 场景列表
  public SCENE_LIST = `${this.HOST}/scene/list`;
  // 场景设备类型下未关联的设备
  public GET_UNBIND_DEVICE_LIST = `${this.HOST}/scene/getUnbindDeviceList`;
  // 场景下的设备
  public DEVICE_LIST_SCENE_ID = (sceneId: any) => `${this.HOST}/scene/getDeviceList/${sceneId}`;
  // 保存节点坐标信息
  public SAVE_LONLAT = `${this.HOST}/scene/saveLonlat`;
  // 添加节点关联线条
  public ADD_LINE = `${this.HOST}/scene/addLine`;
  // 删除节点关联线条
  public DEL_LINE = `${this.HOST}/scene/delLine`;
  // 获取场景下节点连接关系
  public GET_LINE_LIST = (id: any) => `${this.HOST}/scene/getLineList/${id}`;
  // 场景设备详情
  public GET_SCENE_DEVICE_INFO = (id: any) => `${this.HOST}/scene/getSceneDeviceInfo/${id}`;
  // 删除场景下的设备并删除关联的路线
  public DEL_SCENE_DEVICE = `${this.HOST}/scene/delSceneDevice`;
  // 修改场景设备节点在线状态
  public EDIT_ONLINE = (id: any) => `${this.HOST}/scene/editOnline/${id}`;
  // 修改场景设备节点故障状态
  public EDIT_STATUS = (id: any) => `${this.HOST}/scene/editStatus/${id}`;
  // 场景设备输入属性
  public GET_IN_ATTRIBUTE_LIST = (id: any) => `${this.HOST}/scene/getInAttributeList/${id}`;
  // 场景设备输出属性
  public GET_OUT_ATTRIBUTE_LIST = (id: any) => `${this.HOST}/scene/getOutAttributeList/${id}`;
  // 场景设备行为
  public GET_ACTION_LIST = (id: any) => `${this.HOST}/scene/getActionList/${id}`;
  // 上游设备属性节点
  public PRE_DEVICE_ATTRIBUTE_LIST = `${this.HOST}/scene/preDeviceAttributeList`;
  // 新增输入属性
  public ADD_IN_ATTRIBUTE = `${this.HOST}/scene/addInAttribute`;
  // 编辑输入属性
  public EDIT_IN_ATTRIBUTE = `${this.HOST}/scene/editInAttribute`;
  // 删除输入属性
  public DEL_IN_ATTRIBUTE = (id: any) => `${this.HOST}/scene/delInAttribute/${id}`;
  // 新增输出属性
  public ADD_OUT_ATTRIBUTE = `${this.HOST}/scene/addOutAttribute`;
  // 编辑输出属性
  public EDIT_OUT_ATTRIBUTE = `${this.HOST}/scene/editOutAttribute`;
  // 删除输出属性
  public DEL_OUT_ATTRIBUTE = (id: any) => `${this.HOST}/scene/delOutAttribute/${id}`;
  // 输出属性详情
  public OUT_ATTRIBUTE_BY_ID = (id: any) => `${this.HOST}/scene/outAttributeById/${id}`;
  // 场景设备节点绑定通信
  public BIND_CORRESPOND = `${this.HOST}/scene/bindCorrespond`;
  // 场景设备节点绑定通信
  public EDIT_CORRESPOND = `${this.HOST}/scene/editCorrespond`;
  // 场景设备节点解绑通信
  public UNBIND_CORRESPOND = (id: any) => `${this.HOST}/scene/unbindCorrespond/${id}`;
  // 场景设备通信列表
  public CORRESPOND_LIST_BY_ID = (id: any) => `${this.HOST}/scene/correspondListById/${id}`;
  // 获取通信详情
  public CORRESPOND_CONFIG = (id: any) => `${this.HOST}/scene/correspondConfig/${id}`;
  // 开始运行
  public SCENE_START = `${this.HOST}/scene/start`;
  // 开始运行运行场景
  public SCENE_RUN = `${this.HOST}/scene/runScene`;
  // 停止运行场景
  public SCENE_STOP = (id: any) => `${this.HOST}/scene/stopScene/${id}`;
  // 场景是否在运行
  public SCENE_STATUS = (id: any) => `${this.HOST}/scene/sceneIsRunning/${id}`;
  // http 通信返回数据结构列表
  public RESPONSE_LIST = (correspondId: any) => `${this.HOST}/correspond/responseList/${correspondId}`;
  // 添加返回数据结构列表
  public ADD_RESPONSE = `${this.HOST}/correspond/addResponse`;
  // 编辑返回数据结构列表
  public EDIT_RESPONSE = `${this.HOST}/correspond/editResponse`;
  // 删除返回数据结构列表
  public DEL_RESPONSE = (id: any) => `${this.HOST}/correspond/delResponse/${id}`;
  // 添加modbus通信
  public ADD_MODBUS_CORRESPOND = `${this.HOST}/correspond/addModbusCorrespond`;
  // 编辑modbus通信
  public EDIT_MODBUS_CORRESPOND = `${this.HOST}/correspond/editModbusCorrespond`;
  // modbus服务是否开启
  public MODBUS_STATUS = (id: any) => `${this.HOST}/correspond/modbusStatus/${id}`;
  // 开启modbusSlave服务
  public OPEN_MODBUS_SLAVE = (id: any) => `${this.HOST}/correspond/openModbusSlave/${id}`;
  // 关闭modbusSlave服务
  public CLOSE_MODBUS_SLAVE = (id: any) => `${this.HOST}/correspond/closeModbusSlave/${id}`;
  // 寄存器列表
  public REGISTER_LIST_BY_CORRESPOND_ID = (id: any) => `${this.HOST}/correspond/registerListByCorrespondId/${id}`;
  // 保存寄存器信息
  public SAVE_REGISTER = `${this.HOST}/correspond/saveRegister`;
  // 修改modbus寄存器信息
  public EDIT_REGISTER = `${this.HOST}/correspond/editRegister`;
  // 删除单个modbus寄存器信息
  public DEL_REGISTER = (id: any) => `${this.HOST}/correspond/delRegister/${id}`;
  // 保存寄存器写入数据
  public WRITE_MODBUS_DATA = `${this.HOST}/correspond/writeModbusData`;
  // 获取寄存器读取数据
  public RED_MODBUS_DATA = (id: any) => `${this.HOST}/correspond/redModbusData/${id}`;
  // 环境配置
  public LOAD_EVENT = `${this.HOST}/scene/loadEvent`;
  // 根据属性id和类型修改属性值
  public UPDATE_ATTRIBUTE_VALUE = `${this.HOST}/scene/updateAttributeValue`;

  /**
   * 事件模拟
   */
  // 添加事件
  public ADD_EVENT = `${this.HOST}/event/addEvent`;
  // 修改事件
  public EDIT_EVENT = `${this.HOST}/event/editEvent`;
  // 删除事件
  public DEL_EVENT = (id: any) => `${this.HOST}/event/delEvent/${id}`;
  // 事件列表
  public EVENT_LIST = `${this.HOST}/event/eventList`;
  // 事件属性列表
  public EVENT_ATTRIBUTE_LIST = (id: any) => `${this.HOST}/event/attributeList/${id}`;
  // 添加事件属性
  public ADD_ATTRIBUTE = `${this.HOST}/event/addAttribute`;
  // 编辑事件属性
  public CONFIG_ATTRIBUTE = `${this.HOST}/event/attributeConfig`;
  // 删除事件属性
  public DEL_ATTRIBUTE = (id: any) => `${this.HOST}/event/delAttribute/${id}`;
  // 事件属性详情
  public ATTRIBUTE_ID = (id: any) => `${this.HOST}/event/attributeById/${id}`;
  // 事件选择器列表
  public EVENT_OPTIONS = `${this.HOST}/event/eventOptions`;
  /**
   * 子系统管理
   */
  // 移除设备与子系统关系
  public DELETE_DEVICE_SYSTEM_INFO = (id: any) => `${this.HOST}/device/remove-system/${id}`;
  // 事件消息列表
  public LIST_EVENT_MESSAGE = `${this.HOST}/event-message/list`;
  // 子系统列表
  public LIST_SUB_SYSTEM = `${this.HOST}/sub-system/list`;
  // 子系统选择器
  public LIST_OF_SELECT_SUB_SYSTEM = `${this.HOST}/sub-system/listOfSelect`;
  // 删除子系统
  public DELETE_SUB_SYSTEM = (id: any) => `${this.HOST}/sub-system/delete/${id}`;
  // 新增子系统
  public ADD_SUB_SYSTEM = `${this.HOST}/sub-system/add`;
  // 修改子系统
  public EDIT_SUB_SYSTEM = `${this.HOST}/sub-system/edit`;
  // 场景选择器
  public LIST_OPTIONS = `${this.HOST}/scene/listOptions`;
  // 添加通信
  public ADD_CORRESPOND = `${this.HOST}/correspond/add`;
  // 编辑Tcp或Udp
  public EDIT_TCP_UDP_CORRESPOND = `${this.HOST}/correspond/editTcpOrUdp`;
  // 删除通信
  public DELETE_CORRESPOND = (id: any) => `${this.HOST}/correspond/del/${id}`;
  // 保存通信名称
  public SAVE_CORRESPOND_NAME = `${this.HOST}/correspond/saveCorrespondName`;
  // 通信列表
  public CORRESPOND_LIST = `${this.HOST}/correspond/list`;
  // 保存http通信请求方式
  public SAVE_CORRESPOND_METHOD = `${this.HOST}/correspond/saveCorrespondMethod`;
  // 保存通信url
  public SAVE_CORRESPOND_URL = `${this.HOST}/correspond/saveCorrespondUrl`;
  // 通信详情
  public CORRESPOND_INFO = (id: any) => `${this.HOST}/correspond/info/${id}`;
  // 添加query参数
  public ADD_QUERY_PARAM = `${this.HOST}/correspond/addQueryParam`;
  // 修改query参数
  public EDIT_QUERY_PARAM = `${this.HOST}/correspond/editQueryParam`;
  // 删除query参数
  public DEL_QUERY_PARAM = (id: any) => `${this.HOST}/correspond/delQueryParam/${id}`;
  // 添加header参数
  public ADD_HEADER_PARAM = `${this.HOST}/correspond/addHeaderParam`;
  // 修改header参数
  public EDIT_HEADER_PARAM = `${this.HOST}/correspond/editHeaderParam`;
  // 删除header参数
  public DEL_HEADER_PARAM = (id: any) => `${this.HOST}/correspond/delHeaderParam/${id}`;
  // 添加body参数
  public ADD_BODY_PARAM = `${this.HOST}/correspond/addBodyParam`;
  // 修改body参数
  public EDIT_BODY_PARAM = `${this.HOST}/correspond/editBodyParam`;
  // 删除body参数
  public DEL_BODY_PARAM = (id: any) => `${this.HOST}/correspond/delBodyParam/${id}`;
  // 保存http通信body参数类型
  public SAVE_CORRESPOND_BODY_TYPE = `${this.HOST}/correspond/saveCorrespondBodyType`;
  // 添加raw类型body参数
  public SAVE_BODY_PARAM = `${this.HOST}/correspond/saveBodyRaw`;
  // 发送请求
  public SEND_CORRESPOND = (id: any) => `${this.HOST}/correspond/sendCorrespond/${id}`;
  // 修改mqtt通信
  public EDIT_MQTT = `${this.HOST}/correspond/editMqtt`;
  // 添加订阅
  public ADD_MQTT_SUBSCRIBE = `${this.HOST}/mqtt/subscribe`;
  // 添加主题
  public ADD_MQTT_PUBLISH = `${this.HOST}/mqtt/addPublish`;
  // 修改订阅
  public EDIT_SUBSCRIBE = `${this.HOST}/mqtt/editSubscribe`;
  // 删除订阅
  public REMOVE_SUBSCRIBE = `${this.HOST}/mqtt/removeSubscribe`;
  // 发布消息
  public PUBLISH_MQTT = `${this.HOST}/mqtt/publish`;
  // 是否与服务器建立连接
  public IS_CONNECTED = (id: any) => `${this.HOST}/mqtt/isConnected/${id}`;
  // 链接mqtt服务
  public CONNECT_MQTT = (id: any) => `${this.HOST}/mqtt/connectMqtt/${id}`;
  // 断开mqtt服务
  public DISCONNECT_MQTT = (id: any) => `${this.HOST}/mqtt/disconnectMqtt/${id}`;
  // 根据clientID查询订阅列表
  public LIST_BY_CLIENT_ID = (id: any) => `${this.HOST}/mqtt/listByClientId/${id}`;
  // 根据clientID查询主题列表
  public PUBLISH_LIST_CLIENT_ID = (id: any) => `${this.HOST}/mqtt/publishList/${id}`;
  // 关联通信
  public BIND_CORRESPOND_SYSTEM = `${this.HOST}/sub-system/bindCorrespond`;
  // 解绑通信
  public UNBIND_CORRESPOND_SYSTEM = `${this.HOST}/sub-system/unBindCorrespond`;
  // 修改通信 /api/
  public EDIT_CORRESPOND_SYSTEM = `${this.HOST}/sub-system/editCorrespond`;
  // 关联通信列表
  public CORRESPOND_LIST_SYSTEM = (id: any) => `${this.HOST}/sub-system/correspondList/${id}`;

  /**
   * 测试任务相关
   */
  // 测试任务列表
  public TASK_LIST = `${this.HOST}/task/list`;
  // 删除任务
  public TASK_DEL = (id: any) => `${this.HOST}/task/del/${id}`;
  // 测试任务下的设备
  public GET_DEVICE_LIST_BY_TASK_ID = (id: any) => `${this.HOST}/task/getDeviceListByTaskId/${id}`;
  // 测试任务下的节点连接关系
  public GET_LINE_LIST_BY_TASK_ID = (id: any) => `${this.HOST}/task/getLineListByTaskId/${id}`;
  // 测试任务节点详情
  public TASK_NODE_INFO = `${this.HOST}/task/nodeInfo`;
  // 任务下节点数据列表信息
  public NODE_DATA_LIST = `${this.HOST}/task/nodeDataList`;
  // 测试任务节点输入属性
  public IN_ATTRIBUTE_LIST = `${this.HOST}/task/inAttributeList`;
  // 测试任务节点输出属性
  public OUT_ATTRIBUTE_LIST = `${this.HOST}/task/outAttributeList`;
  // 测试任务节点行为列表
  public TASK_ACTION_LIST = `${this.HOST}/task/actionList`;
  // 测试任务通信列表
  public TASK_CORRESPOND_LIST = `${this.HOST}/task/correspondList`;
  // 测试任务详情
  public TASK_INFO = (id: any) => `${this.HOST}/task/info/${id}`;
  // 测试任务执行记录
  public RECORD_LIST = (id: any) => `${this.HOST}/task/recordList/${id}`;
  // 设备关联的事件消息
  public RECORD_LIST_BY_DEVICE_ID = `${this.HOST}/task/recordListByDeviceId`;
  // 场景关联的事件消息
  public RECORD_LIST_BY_SCENE_ID = `${this.HOST}/task/recordListBySceneId`;
  // 根据场景查询测试任务列表
  public LIST_BY_SCENE_ID = `${this.HOST}/task/listBySceneId `;
  // 子系统关联的事件消息
  public RECORD_LIST_BY_SUBSYSTEM_ID = `${this.HOST}/task/recordListBySubsystemId `;
  // 事件消息列表
  public RECORD_LIST_BY_PARAM = `${this.HOST}/task/recordListByParam `;
}
